import { EyeballString } from './eyeball-string'

declare var $

interface Vector {
  x: number
  y: number
}

interface Blink {
  val: number,
  direction: 'closing'|'openning'|'open',
}

export class Eyeball {
  el: typeof $
  clickable: boolean
  container: typeof $
  iris: typeof $
  start: number | undefined
  current: Vector
  target: Vector
  tracking: boolean
  blink: Blink
  speed: Vector
  location: Vector
  moving: boolean
  stringCanvas: HTMLCanvasElement
  stringObj: EyeballString
  playing: boolean

  constructor(el, clickable = false) {
    this.el = $(el)
    this.clickable = clickable
    this.container = $(el).parent()
    this.start = undefined
    this.current = {x:0, y:0}
    this.target = {x:0, y:0}
    this.tracking = false
    this.blink = {val: 1, direction: 'open'}
    this.speed = {x: 0, y: 0}
    this.location = {x: 0, y: 0}
    this.moving = true
    this.playing = false
    this.init()
    return this
  }

  init () {
    const ball = $('<div class="ball"/>').appendTo(this.el)
    if (this.clickable) $('<div class="highlight"/>').appendTo(this.el)
    this.iris = $('<div class="iris"><div class="pupil"/></div>').appendTo(ball)
    this.stringCanvas = $('<canvas class="string-canvas" />').appendTo(this.el).get(0)
    this.stringObj = new EyeballString( this.stringCanvas, this.rand(0, 100) )

    if (this.clickable) {
      $('body').on('mousemove', (e:MouseEvent) => { this.lookAtMouse(e) })
    }

    this.location = {
      x: this.el.offset().left + (this.el.width() * 0.5),
      y: this.el.offset().top + (this.el.height() * 0.5)
    }

    if (this.location.x < window.innerWidth * 0.5) {
      $('.highlight', this.el).addClass('left')
    }

    this.play()

    setTimeout(() => {
      this.randomBlink()
    }, 15000 + (5000 * Math.random()))
  }

  lookAt(x,y) {
    this.tracking = false
    this.target = this.getDeltasClamped(x, y, undefined)
  }

  lookAhead() {
    this.tracking = false
    this.target = {x:0, y:0}
  }

  lookAtMouse(e:MouseEvent) {
    const center = this.getEyeCenter()
    const dX = (e.clientX + window.scrollX - center[0])
    const dY = (e.clientY + window.scrollY - center[1])

    const target = this.getDeltasClamped( dX, dY, window.innerWidth * 0.33 )
    const dD = this.getDistance(target.x, target.y)

    if (dD > 0.66) {
      this.lookAt(this.speed.x, this.speed.y)
    } else if (this.tracking === false) {
      this.tracking = true
      this.startBlink()
      this.target = target
    } else {
      this.target = target
    }
  }

  step(timeStamp) {
    if (this.start === undefined) this.start = timeStamp
    const elapsed = timeStamp - (this.start || 0)
    this.start = timeStamp

    if (elapsed > 0) {
      this.stringObj.draw( timeStamp )
      if (!this.clickable) this.updateLocation()
      this.updateBlink()

      const inc = this.tracking?0.1:0.05
      const thres = this.tracking?0.02:0.01
      const dX = this.towards(this.current.x, this.target.x, inc, thres)
      const dY = this.towards(this.current.y, this.target.y, inc, thres)
      const dA = this.getAngle(dX, dY)
      const dD = this.getDistance(dX, dY)

      this.iris.css({ 
        left: dD > 0.005?((this.easeRatio(1-dX, 1) * 50) + 50)+'%':'50%', 
        top: dD > 0.005?((this.easeRatio(1-dY, 1) * 50) + 50)+'%':'50%',
        transform: dD > 0.005?'translate(-50%,-50%) rotate('+(dA+90)+'deg) scaleY('+(this.easeRatio(dD, 1) * 0.95)+') rotate(-'+(dA+90)+'deg)':'',
        clipPath: 'ellipse(100% '+(this.blink.val * 100)+'% at 50% 50%)'
      })

      this.current = { x: dX, y: dY }

    }

    if (this.playing) {
      window.requestAnimationFrame((ts) => { this.step(ts) })
    }
  }

  play() {
    if (this.playing) return
    this.playing = true
    window.requestAnimationFrame((ts) => { this.step(ts) })
  }

  pause() {
    this.playing = false
  }

  getEyeCenter() {
    const os = this.el.offset()
    return [ os.left + (this.el.width() * 0.5), os.top + (this.el.height() * 0.5) ]
  }
  getDeltasClamped = function(dX, dY, dM){
    if (dX === 0 && dY === 0) return {x: 0, y:0}
    const dist = this.getDistance(dX, dY)
    if (dM === undefined) dM = dist * 1.5
    const rD = Math.min(0.9, dist/dM)
    return {
      x: dX * rD/dM,
      y: dY * rD/dM
    }
  }
  getAngle = function(dX, dY){
    var angle = Math.atan2(dY, dX)
    var degrees = 180 * angle / Math.PI
    return (360 + Math.round(degrees)) % 360
  }
  getDistance = function(dX, dY){
    return Math.sqrt((dY * dY) + (dX * dX))
  }
  easeRatio = function(v, max) {
    return Math.max(-1, Math.min(1, Math.sin((1 - (v/max)) * Math.PI * 0.5)))
  }
  towards = function(current, target, inc = 0.05, threshold = 0.01) {
    const diff = target - current
    return (Math.abs(diff) < threshold)?target:current + (diff * inc)
  }


  /**
   * Blink
   */

  updateBlink = function() {
    if (this.blink.direction === 'closing') {
      this.blink.val -= 0.1
      if (this.blink.val < 0) {
        this.blink.val = 0
        this.blink.direction = 'openning'
      }
    } else if (this.blink.direction === 'openning') { 
      this.blink.val += 0.1
      if (this.blink.val > 1) {
        this.blink.val = 1
        this.blink.direction = 'open'
      }
    }
  }

  startBlink() {
    this.blink.direction = 'closing'
  }

  randomBlink() {
    if (this.blink.direction === 'open') this.blink.direction = 'closing'
    setTimeout(() => {
      this.randomBlink()
    }, this.rand(6000, 12000))
  }


  /**
   * Location
   */

  updateLocation() {
    if (this.moving) {
      this.location = { 
        x: this.location.x + this.speed.x, 
        y: this.location.y + this.speed.y
      }
      if (this.location.y < -100) { 
        this.location = {
          x: this.rand(-100, this.container.width()-100), 
          y: this.container.height() + 100 
        }
      }
      if (this.location.x > this.container.width() + 100) { 
        this.location = {
          x: -100,
          y: this.rand(-100, this.container.height()-100)
        }
      }
      this.el.css({left:this.location.x+'px', top:this.location.y+'px'})
    }
  }

  /**
   * Helpers
   */

  rand(min, max) {
    return min + Math.floor(Math.random() * (max - min + 1))
  }


  /**
   * Public functions
   */

  showClickInvite() {
    this.moving = false
    this.lookAhead()
    this.startBlink()
    const center = this.getEyeCenter()
    this.el.addClass('mouseover')
  }
  hideClickInvite() {
    this.moving = true
    this.setSpeed(this.speed.x, this.speed.y)
    this.el.removeClass('mouseover')
  }
  setSpeed(x,y) {
    this.speed = {x: x, y: y}
    if (this.moving) {
      this.lookAt( x, y )
    }
  }
}