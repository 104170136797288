declare var $

export class SocialShare {
  el: typeof $
  type: 'facebook' | 'twitter' | 'linkedin' | 'email'

  constructor(el, type) {
    this.el = $(el)
    this.type = type
    this.init()
  }

  init() {
    const href = window.location.href
    this.el.attr('href', this.getSharer() + href)
    this.el.on('click', (e)=>{
      if ( this.type === 'email') return
      const shareWindow = window.open( this.getSharer() + href, 'keepsightSocialShare', 'popup,width=520,height=500' )
      if (shareWindow) {
        e.preventDefault()
      }
    })
  }

  getSharer () {
    switch (this.type) {
      case 'facebook':
        return 'https://www.facebook.com/sharer/sharer.php?u='
        break
      case 'twitter':
        return 'https://twitter.com/intent/tweet?text='
        break
      case 'linkedin':
        return 'https://www.linkedin.com/shareArticle?mini=true&url='
        break
      case 'email':
        return 'mailto:?subject=Hold%20On%20to%20your%20Sight%20with%20KeepSight&body=Take%20a%20look%20at%20these%20stories%20about%20why%20regular%20eye%20checks%20are%20so%20important%20when%20you%20have%20diabetes.%0A%0ALife%20is%20precious%3B%20and%20so%20is%20your%20vision.%0A%0A'
        break
      default:
        return ''
    }
  }
}