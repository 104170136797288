import { Video } from "../data/videos";
import { isTabletLandscape } from "./utils";
import { gsap } from "gsap";

declare var $;
declare var Vimeo;

export class VideoOverlay {
  overlay: typeof $;
  player: typeof $;
  content: typeof $;
  closer: typeof $;
  vimeo: typeof Vimeo;
  isMobile: boolean;
  slug: string;
  tweens: gsap.core.Tween[] = [];

  constructor() {
    this.overlay = $(
      '<div class="video-overlay" role="dialog" aria-modal="true" aria-labelledby="overlayTitle" aria-describedby="overlayDesc" aria-hidden="true"/>'
    ).appendTo($("body"));
    this.isMobile = isTabletLandscape();
    this.init();
  }

  init() {
    this.slug = "";
    this.player = $(
      '<div id="video-player" class="video-player paused"><div id="vimeo" class="vimeo" /></div>'
    ).appendTo(this.overlay);
    this.content = $('<div class="video-content"/>').appendTo(this.overlay);
    this.closer = $(
      '<button class="close-overlay" role="button" aria-label="Close video">Close</button>'
    ).appendTo(this.overlay);

    this.closer.on("click", () => {
      this.close();
    });
    this.player.on("click", ".play-control", () => {
      if (this.player.hasClass("paused")) {
        this.playVimeo();
      } else {
        this.pauseVimeo();
      }
    });
    $(window).on("keyup", (e) => {
      if (this.isOpen() && e.key === "Escape") this.close();
    });

    if (this.isMobile) {
      this.content
        .on("click", ".fullscreen-control", (e) => {
          this.toggleFullscreen();
        })
        .on("click", (e) => {
          if ($(e.currentTarget).hasClass(".fullscreen-control")) return;
          $("p", this.content).slideToggle();
          this.content.toggleClass("open");
        });
    } else {
      $('<div class="play-control" />').appendTo(this.player);
    }

    this.buildVimeo();
  }

  private killTweens() {
    for (const tween of this.tweens) {
      tween.kill();
    }
  }

  open() {
    this.overlay.addClass("open").removeAttr("aria-hidden");
    $("html").addClass("video-overlay-open");

    // Stop closing tweens
    this.killTweens();

    // Start opening tweens
    this.tweens = [
      gsap.fromTo(this.player, { y: "-100vh" }, { y: 0, duration: 0.5 }),
      gsap.fromTo(
        this.content,
        { x: this.isMobile ? "100vw" : "50vw" },
        { x: 0, duration: 0.5, delay: 0.2 }
      ),
      gsap.fromTo(
        this.closer,
        { y: "25vh" },
        { y: 0, duration: 0.3, delay: 0.3 }
      ),
    ];

    // Update URL
    //history.pushState(null, "", "#"+this.slug)
  }

  private afterCloseContent() {
    this.content.removeClass("open");
    if (this.isMobile) {
      $("p", this.content).slideUp();
    }
  }

  close() {
    // Stop opening tweens
    this.killTweens();

    // Start closing tweens
    this.tweens = [
      // Animate player exit to top
      gsap.fromTo(this.player, { y: 0 }, { y: "-100vh", duration: 0.5 }),
      // Animate content exit to right side
      gsap.fromTo(
        this.content,
        { x: 0 },
        {
          x: this.isMobile ? "100vw" : "50vw",
          duration: 0.5,
          onComplete: () => {
            this.afterCloseContent();
          },
          onInterrupt: () => {
            this.afterCloseContent();
          },
        }
      ),
      // Animate close button exit to bottom
      gsap.fromTo(this.closer, { y: 0 }, { y: "25vh", duration: 0.3 }),
    ];

    this.overlay.removeClass("open").attr("aria-hidden", "true");
    $("html").removeClass("video-overlay-open").trigger("videooverlayclose");
    this.pauseVimeo();
    if (this.isMobile) {
      this.exitFullscreen();
    }

    // Update URL
    //history.pushState(null, "", "#")
  }

  isOpen() {
    return this.overlay.hasClass("open");
  }

  setContent(video: Video) {
    const contentArr: string[] = [];
    contentArr.push('<h2 id="overlayTitle">' + video.title + "</h2>");
    contentArr.push('<p id="overlayDesc">' + video.description + "</p>");
    if (this.isMobile) {
      contentArr.push('<div class="fullscreen-control" />');
    }
    this.content.html(contentArr.join(""));
    this.loadVimeo(video.url);
    this.slug = video.slug;
  }

  /**
   * Video player
   */

  buildVimeo() {
    const options = {
      url: "https://vimeo.com/872438046", // Wedding as default
      width: this.player.width(),
      byline: false,
      controls: this.isMobile, //false,
      autoplay: false,
      loop: false,
      muted: false,
      portrait: false, // Video owner's portrait
      title: false,
      transcript: true,
    };
    try {
      // @ts-ignore
      this.vimeo = new Vimeo.Player($(".vimeo", this.player), options);
    } catch (error) {
      console.log("Player build", error);
    }
  }

  loadVimeo(url) {
    this.vimeo
      .loadVideo(url)
      .then(function (id) {
        // the video successfully loaded
      })
      .catch(function (error) {
        switch (error.name) {
          case "TypeError":
            console.log("Vimeo load: the ID was not a number");
            break;
          case "PasswordError":
            console.log(
              "Vimeo load: The video is password-protected and the viewer needs to enter the password first"
            );
            break;
          case "PrivacyError":
            console.log(
              "Vimeo load: The video is password-protected or private"
            );
            break;
          default:
            console.log("Vimeo load: Some other error occurred");
        }
      });
  }

  playVimeo() {
    this.player.removeClass("paused");
    this.vimeo.play();
  }

  pauseVimeo() {
    this.player.addClass("paused");
    this.vimeo.pause();
  }

  enterFullscreen() {
    this.vimeo.requestFullscreen();
    //this.playVimeo()
  }

  exitFullscreen() {
    this.vimeo.exitFullscreen();
    //this.pauseVimeo()
  }

  toggleFullscreen() {
    this.vimeo.getFullscreen().then((fullscreen) => {
      this.afterCloseContent();
      if (fullscreen) {
        this.exitFullscreen();
      } else {
        this.enterFullscreen();
      }
    });
  }
}
