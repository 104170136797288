import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

declare var $

export class GsapWrapper {
  el: HTMLElement

  constructor(el) {
    this.el = el
    this.init()
  }

  init() {
    $("[data-scroll-speed]").each((i,el) => {
      const dist = $(el).data('scroll-speed')
      gsap.set(el, {y: 0})
      gsap.to(el, {
          scrollTrigger: {
            id: 'relative'+i,
            trigger: this.el,
            start: "top bottom",
            end: "bottom top",
            scrub: 0,
            //markers: true,
          },
          y: -1 * dist
        })
    })
  }
}