import { applyModule, applyModules } from "./modules/utils"
import { videos } from "./data/videos"
import { EyeballSwarm } from "./modules/eyeball-swarm"
import { VideoOverlay } from "./modules/video-overlay"
import { GsapWrapper } from "./modules/gsap-wrapper"
import { SocialShare } from "./modules/social-share"
import { ScrollWatcher } from "./modules/scroll-watcher"

declare var jQuery

const videoOverlay = new VideoOverlay()
const swarm = new EyeballSwarm('.eyeball-layer-clickable', '.eyeball-layer-background', videos, 20, videoOverlay )
applyModules( '[data-scroll-container]',  GsapWrapper )
applyModule(  '.social-links a.facebook', SocialShare, 'facebook' )
applyModule(  '.social-links a.twitter',  SocialShare, 'twitter' )
applyModule(  '.social-links a.linkedin', SocialShare, 'linkedin' )
applyModule(  '.social-links a.email',    SocialShare, 'email' )
new ScrollWatcher(40)

;(function($){

  $('html').removeClass('no-js')
  
  // Open video based on hash
  if (location.hash) {
    const hash = location.hash.substring(1)
    swarm.openByHash(hash)
  }

})(jQuery)
