declare var $

export class EyeballString {
	canvas: HTMLCanvasElement
	ctx: CanvasRenderingContext2D
  offset: number

  constructor (canvas, offset = 0) {
    this.canvas = canvas
    this.ctx = canvas.getContext("2d")
    this.offset = offset
    this.init() 
  }

  init() {
    this.canvas.width = this.canvas.offsetWidth
		this.canvas.height = this.canvas.offsetHeight
  }

  pointCalc(delta: number, min: number, travel: number, offset: number) {
		return min + (Math.sin(delta + offset) + 1) * 0.5 * travel
	}

  draw(delta: DOMHighResTimeStamp) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    
    const ds = delta * 0.001
    const w = this.canvas.width
    const h = this.canvas.height
		const travel = w * 0.5
    const wt = w * 0.15

    const wc1 = this.pointCalc(ds - 0.5, wt, travel, this.offset)
		const wc2 = this.pointCalc(ds - 1.0, wt, travel, this.offset)
		const wm = this.pointCalc(ds - 1.5, wt, travel, this.offset)
    const wc3 = (wm * 2) - wc2
		const wc4 = this.pointCalc(ds - 2.0, wt, travel, this.offset)
		const we = this.pointCalc(ds - 2.5, wt, travel, this.offset)

    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#FFF'
    this.ctx.beginPath()
    this.ctx.moveTo(w * 0.5, 1)
    this.ctx.bezierCurveTo( (w * 0.5) - 2, 0, (w * 0.5) - 8, 7, (w * 0.5) - 8, 10 )
    this.ctx.bezierCurveTo( wc1, h * 0.177, wc2, h * 0.333, wm, h * 0.5 )
    this.ctx.bezierCurveTo( wc3, h * 0.677, wc4, h * 0.833, we, h )
    this.ctx.stroke()

    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = '#E1DDCC'
    this.ctx.beginPath()
    this.ctx.moveTo(w * 0.5, 1)
    this.ctx.bezierCurveTo( (w * 0.5) - 2, 0, (w * 0.5) - 8, 7, (w * 0.5) - 8, 10 )
    this.ctx.bezierCurveTo( wc1 - 1, h * 0.177, wc2 - 1, h * 0.333, wm - 1, h * 0.5 )
    this.ctx.bezierCurveTo( wc3 - 1, h * 0.677, wc4 - 1, h * 0.833, we - 1, h )
    this.ctx.stroke()
  }

}