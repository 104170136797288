export interface Video {
  id: string;
  slug: string;
  url: string;
  title: string;
  description: string;
}

export const videos: Video[] = [
  {
    id: "01",
    slug: "wedding",
    url: "https://vimeo.com/872438046?share=copy",
    title: "Hold on to seeing big moments",
    description:
      "If you're living with diabetes, KeepSight can help you hold on to your sight with free diabetes eye check reminders.",
  },
  {
    id: "02",
    slug: "soccer-game",
    url: "https://vimeo.com/872438018?share=copy",
    title: "Hold on to seeing small joys",
    description:
      "If you're living with diabetes, KeepSight can help you hold on to your sight with free diabetes eye check reminders.",
  },
  {
    id: "03",
    slug: "puppy",
    url: "https://vimeo.com/872438006?share=copy",
    title: "Hold on to seeing best friends",
    description:
      "If you're living with diabetes, KeepSight can help you hold on to your sight with free diabetes eye check reminders.",
  },
  {
    id: "04",
    slug: "first-steps",
    url: "https://vimeo.com/872437992?share=copy",
    title: "Hold on to seeing them grow",
    description:
      "If you're living with diabetes, KeepSight can help you hold on to your sight with free diabetes eye check reminders.",
  },
  {
    id: "05",
    slug: "world-trip",
    url: "https://vimeo.com/872438063?share=copy",
    title: "Hold on to seeing the world",
    description:
      "If you're living with diabetes, KeepSight can help you hold on to your sight with free diabetes eye check reminders.",
  },
];
