declare var $

export class ScrollWatcher {
  threshold: number
  scrollClass: string

  constructor(threshold, scrollClass = 'scrolled') {
    this.threshold = threshold
    this.scrollClass = scrollClass
    this.init()
  }

  init() {
    this.checkScroll()
    $(window).on('scroll', ()=>{
      this.checkScroll()
    })
  }

  checkScroll() {
    $('html').toggleClass(this.scrollClass, window.scrollY > this.threshold)
  }
}