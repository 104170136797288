import { Video } from "../data/videos";
import { isHoverableDevice } from "./utils";
import { Eyeball } from "./eyeball";
import { VideoOverlay } from "./video-overlay";

declare var $;

interface Vector {
  x: number;
  y: number;
}

export class EyeballSwarm {
  fgel: typeof $;
  bgel: typeof $;
  eyeballs: Eyeball[];
  videos: Video[];
  videoOverlay?: VideoOverlay;
  count: number;
  isHoverable: boolean;

  constructor(fgel, bgel, videos: Video[] = [], count = 20, videoOverlay) {
    this.fgel = $(fgel);
    this.bgel = $(bgel);
    this.eyeballs = [];
    this.videos = videos;
    this.videoOverlay = videoOverlay;
    this.count = count;
    this.isHoverable = isHoverableDevice();
    this.init();
  }

  init() {
    if (this.count > 0) this.buildBackgroundEyeballs();
    if (this.videos.length > 0) this.buildForegroundEyeballs();

    // Listen for video overlay closing
    $("html").on("videooverlayclose", () => {
      this.playAll();
    });

    // Open focused eyeball on space keyup
    $(window).on("keydown", (e) => {
      const focusedeye = $(".eyeball.mouseover, .eyeball:focus", this.fgel);
      if (
        focusedeye.data("video") &&
        e.code === "Space" &&
        this.videoOverlay &&
        !this.videoOverlay.isOpen()
      ) {
        e.preventDefault();
        this.openOverlay(focusedeye.data("video"));
      }
    });

    // Hilight center eyeball on mobile
    if (!this.isHoverable) {
      const highlightEyeball = () => {
        const hittop = window.scrollY + window.innerHeight * 0.25;
        const hitbot = window.scrollY + window.innerHeight * 0.75;
        $(".eyeball", this.fgel).each((i, el) => {
          const os = $(el).position().top;

          if (i === 0) {
            // If it is first eyeball, doesn't matter if it is not centered
            $(el).toggleClass("mouseover", os > hittop);
          } else {
            $(el).toggleClass("mouseover", os > hittop && os < hitbot);
          }
        });
      };
      // Always show hover effect for the first eyeball
      highlightEyeball()
      $(window).on("scroll", highlightEyeball)
    }
    // Always show hover effect for the first eyeball
    if (this.isHoverable) {
      $(".eyeball", this.fgel).eq(0).addClass("mouseover")
    }
  }

  /**
   * Build Foreground Eyeballs
   */

  buildForegroundEyeballs() {
    const w = this.fgel.width() * 0.8;
    const ow = this.fgel.width() * 0.1;
    const h = this.fgel.height() - window.innerHeight;
    const oh = window.innerHeight;

    const dist = this.distributeFixed(this.fgel.width(), this.fgel.height());

    this.videos.forEach((video, i) => {
      const videye = $(
        '<div class="eyeball" tabindex="0" aria-label="' +
          video.title +
          '" data-scroll-speed="250" style="left:' +
          dist[i].x +
          "px;top:" +
          dist[i].y +
          'px;"></div>'
      ).appendTo(this.fgel);
      const eye = new Eyeball(videye.get(0), true);
      eye.setSpeed(0, 0);
      $(videye)
        .data("video", video)
        .data("eye", eye)
        .on("click", (e) => {
          const vid = $(e.currentTarget).data("video");
          this.openOverlay(vid);
        })
        .on("mouseover", (e) => {
          $(e.currentTarget).data("eye").showClickInvite();
        })
        .on("mouseleave", (e) => {
          $(e.currentTarget).data("eye").hideClickInvite();
        });
      this.eyeballs.push(eye);
    });
  }

  /**
   * Build Background Eyeballs
   */

  buildBackgroundEyeballs() {
    const sizeMap = ["xsmall", "small", "medium"];
    const layers = [
      Math.floor(this.count * 0.5),
      Math.floor(this.count * 0.3),
      this.count - Math.floor(this.count * 0.5) - Math.floor(this.count * 0.3),
    ];

    for (let l = 0; l < 3; l++) {
      let dist = this.distribute(
        this.bgel.width(),
        this.bgel.height(),
        layers[l]
      );

      for (let i = 0; i < layers[l]; i++) {
        const speedFactor = (l + 1) / 4;
        const eyepos = $(
          '<div class="eyeball eyeball-' +
            sizeMap[l] +
            '" data-scroll-speed="' +
            -250 * (3 - l) +
            '" style="left:' +
            dist[i].x +
            "px;top:" +
            dist[i].y +
            'px;" aria-hidden="true"></div>'
        ).appendTo(this.bgel);
        const eye = new Eyeball(eyepos.get(0), false);
        eye.setSpeed(0.75 * speedFactor, -0.75 * speedFactor);
        this.eyeballs.push(eye);
      }
    }
  }

  /**
   * Overlay controls
   */

  openOverlay(vid) {
    if (this.videoOverlay) {
      this.videoOverlay.setContent(vid);
      this.videoOverlay.open();
      this.pauseAll();
    }
  }

  pauseAll() {
    this.eyeballs.forEach((eye) => {
      eye.pause();
    });
  }

  playAll() {
    this.eyeballs.forEach((eye) => {
      eye.play();
    });
  }

  /**
   * Helpers
   */

  rand(min, max) {
    return min + Math.floor(Math.random() * (max - min + 1));
  }

  getDistance = function (dX, dY) {
    return Math.sqrt(dY * dY + dX * dX);
  };

  distribute(w, h, n) {
    const d = Math.floor(this.getDistance(w, h) / (0.7 * n));
    const maxIterations = 100;
    let iteration = 0;
    let i = 1;
    let maxD = 0;
    let minD = Infinity;
    let testD = 0;
    let passed = true;
    let backup: Vector = { x: 0, y: 0 };
    let candidate: Vector = { x: this.rand(0, w), y: this.rand(0, h) };
    let distribution: Vector[] = [];

    distribution.push(candidate);

    while (distribution.length < n) {
      candidate = { x: this.rand(0, w), y: this.rand(0, h) };
      passed = true;
      maxD = 0;
      minD = Infinity;
      for (let j = 0, jlen = distribution.length; j < jlen; j++) {
        testD = this.getDistance(
          candidate.x - distribution[j].x,
          candidate.y - distribution[j].y
        );
        if (testD < d) {
          passed = false;
          if (testD < minD) minD = testD;
        }
      }
      if (passed) {
        iteration = 0;
        distribution.push(candidate)
        continue
      } else {
        iteration++
      }
      if (minD > maxD) {
        maxD = minD
        backup = candidate
      }
      if (iteration > maxIterations) {
        iteration = 0
        distribution.push(backup)
      }
    }
    return distribution;
  }

  distributeFixed(w, h) {
    const xFactor = w / 1440
    const screenH = (h + 250) / 3.5 // Depends on screen-full height and gsap scroll
    const clickableArr = [{ x: 1059 * xFactor, y: Math.max(screenH * 0.72, ($(window).height() * 0.5) + 300) }]

    if (this.videos.length > 5)
      clickableArr.push({ x: 800 * xFactor, y: screenH * 1.1 })
    if (this.videos.length > 2)
      clickableArr.push({ x: 181 * xFactor, y: screenH * 1.15 })
    if (this.videos.length > 6)
      clickableArr.push({ x: 50 * xFactor, y: screenH * 1.6 })
    if (this.videos.length > 1)
      clickableArr.push({ x: 927 * xFactor, y: screenH * 1.75 })
    if (this.videos.length > 3)
      clickableArr.push({ x: 856 * xFactor, y: screenH * 2.75 })
    if (this.videos.length > 4)
      clickableArr.push({ x: 240 * xFactor, y: screenH * 3.1 })

    return clickableArr;
  }

  /**
   * Open by hash
   */

  openByHash(hash) {
    const vid = this.videos.find((vid) => vid.slug === hash);
    if (vid) this.openOverlay(vid);
  }
}
