export function applyModule ( selector: string, module, ...args ) {
  const el = document.querySelector(selector)
  if(el) { new module(el, ...args) }
}

export function applyModules ( selector: string, module, ...args ) {
  let els = document.querySelectorAll(selector)
  if(els) { 
    els.forEach(el => { new module(el, ...args) })
  }
}

export function isMobile () {
  return (window.innerWidth <= 767)
}

export function isTablet () {
  return (window.innerWidth <= 1023)
}

export function isTabletLandscape () {
  return (window.innerWidth <= 1199)
}

export function isDesktop () {
  return (window.innerWidth >= 1200)
}

export function isHoverableDevice () {
  return window.matchMedia( '(hover: hover) and (pointer: fine)' ).matches
}
